import {
	ArticleNew,
	StrapiFeaturedArticlesStrapiObject,
} from '@/types/articleTypes';
import classNames from 'classnames';
import { getFeaturedImage } from '@/helpers/strapi/getFeaturedImageNew';
import { ArticleCard } from '../articles/ArticleCard';
import { decodeHtmlEntity } from '@/helpers/htmlEntityDecode/htmlEntityDecode';
import { HeadingText } from '@/components/atoms/HeadingText';
import getArticlesByPage from './getArticlesByPage';
import CompactArticles from '../articles/CompactArticles/CompactArticles';

export interface StrapiFeaturedArticlesSuspendedProps {
	page: 'Homepage' | 'Confirmation Page (Infinity)' | 'Blog Landing';
	headingText?: string;
	containerClassName?: string;
	style?: 'classic' | 'compact';
	cols?: 2 | 3 | 4;
}

const StrapiFeaturedArticlesSuspended = async (
	props: StrapiFeaturedArticlesSuspendedProps,
) => {
	const {
		page,
		headingText,
		containerClassName,
		cols = 4,
		style = 'classic',
	} = props;

	const fetchArticles = async () => {
		// This API URL corresponds with the Strapi Global Components / Featured Articles section
		const response = await fetch(
			`${process.env.NEXT_PUBLIC_CMS_API_URL}/api/global-components/featured-articles`,
		);

		const featuredArticlesComponent: StrapiFeaturedArticlesStrapiObject =
			await response.json();

		if (featuredArticlesComponent) {
			const articles = getArticlesByPage(featuredArticlesComponent, page);
			return articles;
		}

		return null;
	};

	const filteredArticles = await fetchArticles();

	if (style === 'compact') {
		return (
			<CompactArticles
				headingText={headingText}
				containerClassName={containerClassName}
				filteredArticles={filteredArticles}
			/>
		);
	}
	return (
		<div className={classNames('grid-layout', containerClassName)}>
			<div
				className={classNames(
					'col-span-4 grid gap-y-10 sm:col-start-2 sm:col-end-8 sm:grid-cols-6 sm:gap-8 lg:col-start-1 lg:col-end-11 2xl:col-end-13',
					{
						'lg:grid-cols-2': cols === 2,
						'lg:grid-cols-3': cols === 3,
						'lg:grid-cols-4': cols === 4,
					},
				)}
			></div>
			<div
				className={classNames(
					'col-span-4 grid gap-y-10 sm:col-start-2 sm:col-end-8 sm:grid-cols-6 sm:gap-8 lg:col-start-1 lg:col-end-11 2xl:col-end-13',
					{
						'lg:grid-cols-2': cols === 2,
						'lg:grid-cols-3': cols === 3,
						'lg:grid-cols-4': cols === 4,
					},
				)}
			>
				<HeadingText
					className="col-span-full mt-8 !text-left text-2xl font-semibold tracking-tight xl:text-3xl"
					tag={'h2'}
				>
					{headingText ?? 'Recent Posts'}
				</HeadingText>

				{filteredArticles?.map((article: ArticleNew) => {
					const featuredImage = getFeaturedImage(article);

					return (
						<div key={article.id} className="col-span-3 lg:col-span-1">
							<ArticleCard
								href={`/expert-advice/${article.slug}`}
								image={{
									alt: featuredImage.alt,
									src: featuredImage.src,
									height: featuredImage.height,
									width: featuredImage.width,
								}}
								title={decodeHtmlEntity(article.title)}
								category={article.category?.title}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default StrapiFeaturedArticlesSuspended;
