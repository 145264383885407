import {
	FeaturedArticles,
	StrapiFeaturedArticlesStrapiObject,
} from '@/types/articleTypes';

function getArticlesByPage(
	component: StrapiFeaturedArticlesStrapiObject,
	title: string,
): FeaturedArticles | null {
	if (!Array.isArray(component.featuredArticles)) {
		return null;
	}

	for (const collection of component.featuredArticles) {
		if (collection.page.includes(title)) {
			return collection.articles;
		}
	}

	return null;
}

export default getArticlesByPage;
