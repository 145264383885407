import React from 'react';
import classNames from 'classnames';
import { HeadingText } from '@/components/atoms/HeadingText';
import { ArticleNew } from '@/types/articleTypes';
import { decodeHtmlEntity } from '@/helpers/htmlEntityDecode/htmlEntityDecode';
import { ArticleCardCompact } from '../ArticleCardCompact';
import Link from 'next/link';
import { IconArrowRight } from '../../icons';

export interface CompactArticlesProps {
	containerClassName?: string;
	headingText?: string;
	filteredArticles: ArticleNew[] | null;
}

const CompactArticles = (props: CompactArticlesProps) => {
	const { containerClassName, headingText, filteredArticles } = props;

	return (
		<div
			className={classNames(
				'grid-layout pb-10 pt-8 sm:pt-0 lg:pb-20',
				containerClassName,
			)}
		>
			<HeadingText className="col-span-full" tag={'h2'} size="h2">
				{headingText ?? 'Recent Posts'}
			</HeadingText>

			<div
				className="col-span-full grid gap-y-4 sm:grid-cols-6 sm:gap-8 lg:col-start-1 lg:col-end-11 lg:auto-rows-fr
					lg:grid-cols-3 lg:gap-y-10 2xl:col-end-13"
			>
				{filteredArticles?.map((article: ArticleNew) => {
					return (
						<div
							key={article.id}
							className="col-span-full md:col-span-3 lg:col-span-1"
						>
							<ArticleCardCompact
								href={`/expert-advice/${article.slug}`}
								title={decodeHtmlEntity(article.title)}
								category={article.category?.title}
							/>
						</div>
					);
				})}
			</div>

			<Link
				href="/expert-advice/"
				className="group col-span-full m-auto flex items-center gap-1 pt-3 text-xl tracking-tight
					text-sky-700 hover:text-sky-800 lg:text-2xl [@media(hover:hover)]:hover:text-sky-800"
			>
				<span>See more</span>
				<IconArrowRight
					className="h-6 w-6 fill-sky-700 group-hover:fill-sky-800 lg:h-7 lg:w-7"
					aria-hidden="true"
				/>
			</Link>
		</div>
	);
};

export default CompactArticles;
