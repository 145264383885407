'use client'; // Error components must be Client Components

import '@/app/globals.css';
import { Button } from '@/components/atoms/Button';
import { StrapiFeaturedArticles } from '@/components/StrapiFeaturedArticles';

interface ErrorProps {
	reset: () => void;
}

export default function Error(props: ErrorProps) {
	const { reset } = props;

	return (
		<main className="grid-layout mt-10">
			<div
				className="col-span-full sm:col-span-6 sm:col-start-2 lg:col-span-8 lg:col-start-2
					2xl:col-span-10 2xl:col-start-2"
			>
				<h1 className={'mb-12 text-center text-3xl font-bold lg:text-5xl'}>
					Uh oh, Something went wrong!
				</h1>

				<div className="mx-auto flex max-w-75 lg:max-w-101">
					<Button
						onClick={
							// Attempt to recover by trying to re-render the segment
							() => reset()
						}
						className="mb-8"
					>
						Try Again
					</Button>
				</div>

				<StrapiFeaturedArticles page="Blog Landing" />
			</div>
		</main>
	);
}
