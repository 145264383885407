import { ArticleNew } from '@/types/articleTypes';

interface ImageProps {
	src: string;
	height: number;
	width: number;
	alt: string;
}

export function getFeaturedImage(article: ArticleNew): ImageProps {
	if (article.featured_image) {
		const attributes = article.featured_image;
		const imageProps = {
			src:
				attributes.url ??
				'https://www.hvac.com/assets/opengraph/og-image-hvac-com.jpg',
			height: attributes.height ?? 1000,
			width: attributes.width ?? 1000,
			alt: attributes.alternativeText
				? attributes.alternativeText
				: 'Oops! Looks like this image is too cool for alt text!',
		};
		return imageProps;
	} else if (article.legacy_seo?.legacy_featured_image_json) {
		const imageProps = {
			src:
				'https://www.hvac.com/wp-content/uploads/' +
				article.legacy_seo.legacy_featured_image_json.file,
			height: article.legacy_seo.legacy_featured_image_json.height,
			width: article.legacy_seo.legacy_featured_image_json.width,
			alt: 'Oops! Looks like this image is too cool for alt text!',
		};
		return imageProps;
	} else {
		const imageProps = {
			src: 'https://www.hvac.com/assets/opengraph/og-image-hvac-com.jpg',
			height: 1000,
			width: 1000,
			alt: `The image for this blog post hasn't been updated yet!`,
		};
		return imageProps;
	}
}
